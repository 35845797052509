<template>
  <div>
    <div class="main-bg-color d-flex w-100">
      <template v-if="forgetStep == 1">
        <ForgetPasswordPanel
          class="w-100"
          :form="form"
          :v="$v"
          v-on:handleNextStep="handleNextStep"
        />
      </template>
      <template v-if="forgetStep == 2">
        <VerifyOTPPanel
          class="w-100"
          :form="form"
          v-on:setOTP="setOTP"
          v-on:handleNextStep="handleNextStep"
        />
      </template>

      <template v-if="forgetStep == 3">
        <SettingNewPasswordPanel
          class="w-100"
          :form="form"
          :v="$v"
          :formForgetPassword="formForgetPassword"
          v-on:handleNextStep="handleNextStep"
        />
      </template>
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlert
      ref="modalAlert"
      :text="modalMessage"
      v-on:hadleModalAlertClose="hadleModalAlertClose"
    />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import ForgetPasswordPanel from "@/components/forget-password/ForgetPasswordPanel";
import VerifyOTPPanel from "@/components/forget-password/VerifyOTPPanel";
import SettingNewPasswordPanel from "@/components/forget-password/SettingNewPasswordPanel";
import ModalLoading from "@/components/alert-modal/ModalLoading";
import ModalAlert from "@/components/alert-modal/ModalAlert";
import ModalAlertError from "@/components/alert-modal/ModalAlertError";
import {
  required,
  minLength,
  sameAs,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
export default {
  components: {
    ForgetPasswordPanel,
    VerifyOTPPanel,
    SettingNewPasswordPanel,
    ModalLoading,
    ModalAlert,
    ModalAlertError
  },
  data() {
    return {
      modalMessage: "",
      sessionId: null,
      forgetStep: 1,
      userData: null,
      activeLanguage: "th",
      form: {
        mobileNo: ""
      },
      formForgetPassword: {
        otpToken: "",
        otpReference: "",
        otp: "",
        mobileNo: "",
        password: "",
        confirmPassword: "",
        userGUID: ""
      }
    };
  },
  validations: {
    form: {
      mobileNo: { required }
    },
    formForgetPassword: {
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6),
        numeric
      },
      confirmPassword: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6),
        numeric,
        sameAsPassword: sameAs("password")
      }
    }
  },
  created: async function () {
    this.sessionId = this.$route.query.sessionId;
    if (this.sessionId) {
      await this.getUserData();
    }
    if (this.$route.params.lang) {
      this.activeLanguage = this.$route.params.lang;
    }
  },
  methods: {
    getUserData: async function () {
      await this.$axios
        .get(this.$baseUrl + "/user/" + this.sessionId)
        .then(response => {
          if (response.data.result === 1) {
            this.userData = response.data.detail;
            this.formForgetPassword.userGUID = response.data.detail.userGUID;
          }
        });
    },
    hadleModalAlertClose() {
      this.$router.push(
        `/login/${this.activeLanguage}?sessionId=${this.sessionId}`
      );
    },
    setOTP(value) {
      this.formForgetPassword.otpToken = value.otpToken;
      this.formForgetPassword.otpReference = value.otpReference;
    },
    handleNextStep(step, otp) {
      if (step == 2) {
        this.$v.form.$touch();
        if (this.$v.form.$error) {
          return;
        }
        this.forgetStep = step;
        this.formForgetPassword.mobileNo = this.form.mobileNo;
      } else if (step == 3) {
        this.forgetStep = step;
        this.formForgetPassword.otp = otp;
      } else if (step == 4) {
        this.checkFormResetPassword();
      }
    },
    checkFormResetPassword() {
      this.$v.formForgetPassword.$touch();
      if (this.$v.formForgetPassword.$error) {
        return;
      }
      this.submitFormResetPassword();
    },
    submitFormResetPassword: async function () {
      this.isDisble = true;
      this.$refs.modalLoading.show();
      let form = {
        otpToken: this.formForgetPassword.otpToken,
        otpReference: this.formForgetPassword.otpReference,
        otp: this.formForgetPassword.otp,
        mobileNo: this.formForgetPassword.mobileNo,
        password: this.formForgetPassword.password,
        userGUID: this.formForgetPassword.userGUID
      };
      await this.$axios
        .post(`${this.$baseUrl}/kerryapi/ResetPassword`, form)
        .then(data => {
          this.isDisble = false;
          if (data.data.result == 1) {
            this.isDisble = false;
            this.modalMessage = this.$t("successResetPassword");
            this.$refs.modalLoading.hide();
            this.$refs.modalAlert.show();
          } else {
            this.modalMessage = data.message;
            this.$refs.modalLoading.hide();
            this.$refs.modalAlertError.show();
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.forget-pass-container {
  min-height: 100vh;
}
.logo-img {
  width: 80px;
  height: auto;
  text-align: center;
}
::v-deep .card {
  border-color: white !important;
  border-radius: 14px;
}

@media only screen and (min-width: 1200px) {
  .forget-pass-card {
    min-width: 450px;
  }
}
</style>