<template>
  <div>
    <div
      class="main-bg-color d-flex forget-pass-container justify-content-center"
    >
      <div class="card forget-pass-card my-auto px-3 py-5 mx-3">
        <div class="logo-image-container text-center px-3 py-5">
          <img
            src="@/assets/images/forgot-pass-icon.svg"
            alt="Kitten"
            class="logo-img"
          />
        </div>
        <div class="text-center">
          <h5>{{ $t("setNewPassword") }}</h5>
        </div>
        <div class="pt-4">
          <InputText
            class="my-2 login-input"
            v-model="formForgetPassword.password"
            :textFloat="$t('newPassword')"
            :placeholder="$t('newPassword')"
            type="password"
            name="password"
            isRequired
            @onKeypress="isNumber($event)"
            :isValidate="v.formForgetPassword.password.$error"
            :v="v.formForgetPassword.password"
            isShowPassword
          />
        </div>
        <div class="pb-4">
          <InputText
            class="my-2 login-input"
            v-model="formForgetPassword.confirmPassword"
            :textFloat="$t('confirmPassword')"
            :placeholder="$t('confirmPassword')"
            type="password"
            name="confirmPassword"
            isRequired
            @onKeypress="isNumber($event)"
            :isValidate="v.formForgetPassword.confirmPassword.$error"
            :v="v.formForgetPassword.confirmPassword"
            isShowPassword
          />
        </div>
        <div>
          <b-button class="w-100 btn-main" @click="handleNextStep">{{
            $t("next")
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
export default {
  components: {
    InputText
  },
  props: {
    formForgetPassword: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    }
  },
  data() {
    return {};
  },
  validations: {},
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleNextStep() {
      this.$emit("handleNextStep", 4);
    }
  }
};
</script>

<style lang="scss" scoped>
.forget-pass-container {
  min-height: 100vh;
}
.logo-img {
  width: 80px;
  height: auto;
  text-align: center;
}
::v-deep .card {
  border-color: white !important;
  border-radius: 14px;
}

.forget-pass-card {
  min-width: 450px;
}

@media (max-width: 767.98px) {
  .forget-pass-card {
    min-width: 320px;
  }
}
</style>