<template>
  <div>
    <div
      class="main-bg-color d-flex forget-pass-container justify-content-center"
    >
      <div class="card forget-pass-card my-auto px-3 py-5 mx-3">
        <div class="logo-image-container text-center px-3 py-5">
          <img
            src="@/assets/images/forgot-pass-icon.svg"
            alt="Kitten"
            class="logo-img"
          />
        </div>
        <div class="text-center">
          <h5>{{ $t("forgotPassword2") }}</h5>
        </div>
        <div class="text-center">
          <span class="f-size-14">{{ $t("pleaseEnterMobileNo") }}</span>
        </div>
        <div class="py-4">
          <InputText
            :textFloat="$t('mobileNo')"
            placeholder="xxx-xxx-xxxx"
            type="tel"
            name="telephone"
            isRequired
            @onKeypress="isNumber($event)"
            v-model="form.mobileNo"
            :isValidate="v.form.mobileNo.$error"
            :v="v.form.mobileNo"
          />
        </div>
        <div>
          <b-button class="w-100 btn-main" @click="handleNextStep">{{
            $t("next")
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
export default {
  components: {
    InputText
  },
  props: {
    form: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    }
  },
  data() {
    return {};
  },
  methods: {
    handleNextStep() {
      this.$emit("handleNextStep", 2);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.forget-pass-container {
  min-height: 100vh;
}
.logo-img {
  width: 80px;
  height: auto;
  text-align: center;
}
::v-deep .card {
  border-color: white !important;
  border-radius: 14px;
}

.forget-pass-card {
  min-width: 450px;
}

@media (max-width: 767.98px) {
  .forget-pass-card {
    min-width: 0;
  }
}
</style>