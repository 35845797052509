<template>
  <div>
    <div
      class="main-bg-color d-flex forget-pass-container justify-content-center"
    >
      <div class="card forget-pass-card my-auto px-3 py-5 text-center mx-3">
        <div class="logo-image-container text-center px-3 py-5">
          <img
            src="@/assets/images/forgot-pass-icon.svg"
            alt="Kitten"
            class="logo-img"
          />
        </div>
        <div>
          <h5>{{ $t("verifyOTP") }}</h5>
        </div>
        <div>
          <div class="f-size-14">
            <div>{{ $t("sendingOTP") }}</div>
            <div>{{ form.mobileNo | enforcePhoneFormat }}</div>
          </div>
        </div>
        <div class="pt-4">
          <InputText
            :placeholder="$t('pleaseEnterOTP')"
            type="tel"
            name="otp"
            v-model="formOTP.OTP"
            textFloat=""
            :isValidate="$v.formOTP.OTP.$error"
            :v="$v.formOTP.OTP"
            @onKeypress="isNumber($event)"
          />
        </div>
        <div class="pb-4">
          <span class="main-color"
            >{{ $t("otpRef") }}: {{ formOTP.otpReference }}</span
          >
        </div>
        <div>
          <b-button
            class="w-100 btn-main"
            @click="submitValidateOTP"
            :disabled="isLoading"
            >{{ $t("next") }}</b-button
          >
        </div>
        <div v-if="!isLoading" class="text-center">
          <div v-if="isValidateOTP" class="mt-3 mb-0 f-size-14">
            <p v-if="timeLeft == 'EXPIRED'" class="mb-0">
              <span class="cursor-pointer" @click="handleClickResend(2)">
                <u>{{ $t("sendAgain") }}</u>
              </span>
            </p>
            <p class="mb-0" v-else>{{ $t("msgSendAgain") }}: {{ timeLeft }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
import {
  required,
  minLength,
  requiredIf,
  integer
} from "vuelidate/lib/validators";
export default {
  components: {
    InputText
  },
  props: {
    form: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      isValidateOTP: false,
      isLoading: false,
      errors: "",
      formOTP: {
        mobileNo: this.form.telephone,
        otpToken: "",
        otpReference: "",
        OTP: ""
      },
      timeLeft: "00:00"
    };
  },
  validations() {
    return {
      formOTP: {
        OTP: {
          minLength: minLength(6),
          integer,
          required: requiredIf(function () {
            return this.isValidateOTP;
          })
        }
      }
    };
  },
  filters: {
    enforcePhoneFormat(phone_number) {
      let x = phone_number
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      phone_number = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
      return phone_number;
    }
  },
  created: async function () {
    await this.sendRequestOTP();
  },
  methods: {
    sendRequestOTP: async function () {
      this.timeLeft = "00:00";
      this.errors = "";
      this.isLoading = true;
      await this.$axios
        .post(`${this.$baseUrl}/kerryapi/GetOtp`, {
          mobileNo: this.form.mobileNo
        })
        .then(async data => {
          this.isLoading = false;
          if (data.data.result == 1) {
            this.isValidateOTP = true;
            this.formOTP = data.data.detail;
            this.$emit("setOTP", data.data.detail);
            this.onCountTime();
          } else {
            this.errors = data.message;
          }
        });
    },
    submitValidateOTP: async function () {
      this.$v.formOTP.$touch();
      if (this.$v.formOTP.$error) return;

      this.$v.formOTP.$reset();
      this.errors = "";
      this.isLoading = true;
      this.handleNextStep();
    },
    handleNextStep() {
      this.$emit("handleNextStep", 3);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleClickResend(flag) {
      this.formOTP.OTP = "";
      this.formOTP.reference = "";
      this.timeLeft = "00:00";
      if (flag == 1) {
        this.isValidateOTP = false;
      } else {
        this.sendRequestOTP();
      }
    },
    onCountTime() {
      let countDownDate = new Date();
      countDownDate.setSeconds(countDownDate.getSeconds() + 180);
      countDownDate = countDownDate.getTime();
      let timerWait = setInterval(() => {
        let now = new Date().getTime();

        let distance = countDownDate - now;

        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        this.timeLeft = minutes + ":" + seconds + "";

        if (distance < 0) {
          clearInterval(timerWait);
          this.timeLeft = "EXPIRED";
          return;
        }
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.forget-pass-container {
  min-height: 100vh;
}
.logo-img {
  width: 80px;
  height: auto;
  text-align: center;
}
::v-deep .card {
  border-color: white !important;
  border-radius: 14px;
}

.forget-pass-card {
  min-width: 450px;
}

::v-deep .div-input {
  margin-bottom: 5px;
}

@media (max-width: 767.98px) {
  .forget-pass-card {
    min-width: 0;
  }
}
</style>